import { colors, fade } from '@material-ui/core';

const black = '#000';
const white = '#fff';

const greyDark = '#151515';
const grey = '#282626';

export default {
  black,
  white,
  divider: black,
  primary: {
    contrastText: black,
    dark: '#bb5800',
    main: '#ff7A00',
    light: '#FFCE44',
  },
  secondary: {
    contrastText: white,
    dark: greyDark,
    main: grey,
    light: '#4F4F4F',
    lightest: '#C4C4C4',
  },
  error: {
    contrastText: white,
    dark: '#a80000',
    main: '#ff0000',
    light: '#ff5c5c',
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  info: {
    contrastText: white,
    main: '#0BC207',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  text: {
    primary: white,
    secondary: fade(white, 0.6),
    disabled: fade(black, 0.38),
    hint: fade(white, 0.5),
    icon: fade(white, 0.5),
  },
  divider: fade(white, 0.2),
  background: {
    paper: '#1F1D1D',
    main: greyDark,
    default: grey,
  },
  temperature: {
    white: white,
    red: colors.red[500],
    blue: colors.blue[500],
  },
};
