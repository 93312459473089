import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  paper: {
    marginRight: -16,
    background: palette.secondary.light,
    border: `1px solid ${fade(palette.white, 0.5)}`,
    boxShadow: `0px 5px 6px ${fade(palette.black, 0.2)}, 0px 3px 16px ${fade(
      palette.black,
      0.12,
    )}, 0px 9px 12px ${fade(palette.black, 0.14)}`,
  },
};
