import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Grow from '@material-ui/core/Grow';

import PaymentForm from 'components/@PaymentForm';
import Layout from 'components/Layout';
import theme from 'theme';
import { useScript } from 'hooks';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        TransitionComponent={Grow}
      >
        <Layout>
          <PaymentForm />
        </Layout>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
