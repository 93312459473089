import palette from 'theme/palette';

export default {
  root: {
    // disabled background around Switch on hover
    '&:hover .MuiIconButton-root .MuiTouchRipple-root': {
      backgroundColor: 'transparent',
    },
  },
  colorPrimary: {
    '& + $track': {
      backgroundColor: '#666',
      opacity: 1,
    },
    '&$checked': {
      color: palette.white,
      '& + $track': {
        opacity: 0.7,
      },
    },
    '&$disabled': {
      color: '#535353',
      '& + $track': {
        opacity: 0.7,
        backgroundColor: '#414141',
      },
    },
  },
};
