import React from 'react';
import {
  Link,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Paper,
} from '@material-ui/core';

import { LogoIcon } from 'assets/icons';
import useStyles from './styles';

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://bartrack.beer/">
      BarTrack
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
);

const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" elevation={0} className={classes.appBar}>
        <Toolbar>
          <LogoIcon />
        </Toolbar>
      </AppBar>
      <Container component="main" className={classes.main} maxWidth="sm">
        <Paper className={classes.paper} variant="outlined">
          {children}
        </Paper>
      </Container>
      <Copyright />
    </React.Fragment>
  );
};

export default Layout;
