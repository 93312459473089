import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  root: {
    margin: '15px 0',
    '&.gradient': {
      backgroundColor: 'transparent',
      backgroundImage: `linear-gradient(to right, transparent 0%, ${fade(
        palette.white,
        0.2,
      )} 40%, ${fade(palette.white, 0.2)} 60%, transparent 100%)`,
    },
  },
  flexItem: {
    height: '100%',
    alignSelf: 'center',
  },
  vertical: {
    height: '100%',
    margin: 0,
  },
};
