import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useScript } from 'hooks';

const MeetingDialog = ({ open, onClose, onAccept }) => {
  useScript('https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js');

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Onboarding Meeting
      </DialogTitle>
      <DialogContent dividers>
        <div
          class="meetings-iframe-container"
          data-src="https://meetings.hubspot.com/hunter84/bartrack-onboarding-call?embed=true"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MeetingDialog;
