import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';
import dompurify from 'dompurify';
import { useSnackbar } from 'notistack';

import api from 'services/API';

const sanitizer = dompurify.sanitize;

const parseShortcodes = (content, agreement) => {
  const startIndex = content.indexOf(startStrings[agreement]); 
  const endIndex = content.indexOf(endStrings[agreement]) + endStrings[agreement].length;
  if (!startIndex || !endIndex) {
    return false;
  } else {
    return content.substring(startIndex, endIndex);
  }
};

const startStrings = {
  eula: "<p><strong>Last Revised:",
  'key-terms': "<p><strong>Last Revised:"
};

const endStrings = {
  eula: 'Customer hereby grants BarTrack and its agents an irrevocable, perpetual, worldwide, non-exclusive, fully paid-up, royalty- free license (with right to sub-license) to use, reproduce, publish, and display your name, trademarks, service marks, designs, logos, and symbols in connection with such purpose.',
  'key-terms': 'advanced training.  Please contact BarTrack for a quote of current pricing.'
}

const AgreementDialog = ({ open, agreement, onClose, onAccept }) => {
  const [content, setContent] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function getMarkup(page) {
      const response = await api.getPages();
      if (!response.data) {
        return enqueueSnackbar('Content failed to load...', {
          variant: 'warning',
          persist: true,
        });
      }
      const dirtyContent = response.data.find(({ slug }) => slug === page)?.content.rendered;
      const cleanContent = parseShortcodes(dirtyContent, agreement);
      if (!cleanContent) {
        return enqueueSnackbar('EULA content parsing error...', {
          variant: 'warning',
          persist: true,
        });
      }

      setContent(cleanContent);
    }
    getMarkup(agreement)
  }, []);

  const onTrigger = useCallback(() => {
    window.open('https://bartrack.beer/eula', '_blank');
  }, []);

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {agreement === 'eula' ? 'End User License Agreement' : 'Key Subscription Terms'}
      </DialogTitle>
      <DialogContent dividers>
        {!content ? (
          <CircularProgress />
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: agreement === 'eula' ? sanitizer(content) : sanitizer(content),
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        {agreement === 'eula' && (
          <Button onClick={onTrigger} color="primary">
            Open in New Tab
          </Button>
        )}
        <Button onClick={onClose} color="primary" variant="outlined">
          Decline
        </Button>
        <Button onClick={onAccept} color="primary" variant="contained">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AgreementDialog;
