import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  root: {
    '&$disabled, &$disabled:hover': {
      color: fade(palette.white, 0.4),
      cursor: 'not-allowed',
    },
  },
  iconOutlined: {
    color: 'inherit',
  },
  iconFilled: {
    color: 'inherit',
  },
};
