import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .MuiFormHelperText-root': {
      color: palette.error.main,
    },
  },
  heading: {
    textAlign: 'center',
  },
  submit: {
    textAlign: 'center',
    paddingTop: '3em',
  },
  cardElement: {
    background: palette.background.default,
    '& .recurly-element': {
      height: 50,
    },
  },
  statement: {
    '& span': {
      color: palette.primary.main,
      cursor: 'pointer',
    },
  },
  slider: {
    '& .MuiSlider-rail': {
      height: 10,
    },
    '& .MuiSlider-track': {
      height: 10,
    },
    '& .MuiSlider-thumb': {
      height: 20,
      width: 20,
    },
  },
  active: {
    border: `1px solid rgba(255, 122, 0, 0.5)`,
    '& .MuiCardContent-root': {
      padding: '16px !important',
    },
  },
  excerpt: {
    '& > span': {
      color: 'rgba(255, 255, 255, 0.4)',
    },
  },
  label: {
    color: 'rgba(255, 255, 255, 0.4)',
  },
  costCard: {
    background: palette.primary.main,
  },
  lessMargin: {
    marginBottom: spacing(2),
  },
  coolerLine: {
    marginBottom: spacing(1),
  },
}));

export default useStyles;
