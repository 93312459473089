import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Box,
  Slider,
  Button,
  Grid,
  CircularProgress,
  Typography,
  Divider,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  InputAdornment,
  FormControl,
  OutlinedInput,
  InputLabel
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import publicIp from 'public-ip';
import { format, endOfWeek, startOfWeek, addDays } from 'date-fns';
import { useSnackbar } from 'notistack';

import api from 'services/API';
import { useQueryParam } from 'hooks';

import AgreementDialog from './AgreementDialog';
import MeetingDialog from './MeetingDialog';
import useStyles from './styles';
import WeekPicker from './WeekPicker';
import Success from './Success';
import SUPPORTED_POS_LIST from 'config/pos';
import { PLANS, POS_COST, SHIPPING_HANDLING_COST, GW_COST, MAX_LINES_PER_COOLER } from 'config/pricing';

const ValueLabelComponent = props => {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

const boolToEnglish = val => (val ? 'Yes' : 'No');

const DEV_DEFAULTS = {
  address: '43434 Turnberry Isle Ct',
  city: 'Leesburg',
  cooler_count: undefined,
  email: 'fuhrst-laaszt@establishment.com',
  establishment_franchise_qty: '',
  establishment_name: 'Testing Demo Establishment',
  first_name: 'Fuhrst ',
  last_name: 'Laaszt',
  notes:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  position: 'Manager',
  referral: 'Austin',
  selected_pos: 'Square',
  state: 'VA',
  tap_count: 4,
  zipcode: '20176',
};

function PaymentForm() {
  const classes = useStyles();

  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [hasMultipleCoolers, setHasMultipleCoolers] = useState(false);
  const [hasAcceptedEULA, setHasAcceptedEULA] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [hasAcceptedSubcriptionDetails, setHasAcceptedSubcriptionDetails] = useState(false);
  const [paymentType, setPaymentType] = useState(null);
  const [tapCount, setTapCount] = useState(1);
  const [coolerCount, setCoolerCount] = useState(1);
  const [currentAgreement, setCurrentAgreement] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(false);
  const [ip, setIp] = useState(false);
  const [initialCost, setInitialCost] = useState(0);
  const [subscriptionCost, setSubscriptionCost] = useState(0);
  const [lineCost, setLineCost] = useState(0);
  const [trainingCost, setTrainingCost] = useState(0);
  const [customLineCost, setCustomLineCost] = useState(null);
  const [customTrainingCost, setCustomTrainingCost] = useState(null);
  const [installWindowStartDate, setInstallWindowStartDate] = useState(
    startOfWeek(addDays(new Date(), 28)),
  );
  const [installWindowEndDate, setInstallWindowEndDate] = useState(
    endOfWeek(addDays(new Date(), 28)),
  );
  const [currentPOS, setCurrentPOS] = useState('');
  const [isAddressDifferent, setIsAddressDifferent] = useState(false);
  const [hasHighABV, setHasHighABV] = useState(false);
  const [hasDifferentUser, setHasDifferentUser] = useState(false);
  const [stainlessLineCount, setStainlessLineCount] = useState(0);
  const [openMeeting, setOpenMeeting] = useState(false);
  const [hasCustomPOSPricing, setHasCustomPOSPricing] = useState(false);
  const [customPOSCost, setCustomPOSCost] = useState(null);
  const [billFrequency, setBillFrequency] = useState(false);

  const [referral] = useQueryParam('ref', '');

  const { enqueueSnackbar } = useSnackbar();

  const { register, control, errors, getValues, handleSubmit, trigger } = useForm({
    mode: 'onBlur',
    defaultValues: window.location.href.includes('localhost') ? DEV_DEFAULTS : {},
  });

  useEffect(() => {
    async function getIP() {
      let response = await publicIp.v4({
        fallbackUrls: ['https://ifconfig.co/ip'],
      });
      setIp(response);
    }
    getIP();
  }, []);

  useEffect(() => {
    hasMultipleCoolers ? setCoolerCount(2) : setCoolerCount(1);
  }, [hasMultipleCoolers]);

  const onSubmit = async () => {
    const isReady = await trigger();
    if (!isReady || Object.getOwnPropertyNames(errors).length !== 0) {
      return enqueueSnackbar('Ensure all fields are filled out', { variant: 'warning' });
    }

    setIsSubmitting(true);
    let values = getValues();
    let data = {
      referral: '',
      address: {},
      rawData: {},
      general: {},
      details: {},
      installation: {},
      coolers: {},
      'POS Information': {},
      'Billing Information': {},
      payment: {},
      training: {},
      'Customer Agreement Information': {},
    };

    data.referral = values.referral;

    data.address = {
      street1: values.address,
      street2: '',
      city: values.city,
      region: values.state,
      postal_code: values.zipcode,
      country: 'United States',
    };

    data.rawData.referral = data.referral;
    data.rawData.address = data.address;
    data.rawData.establishment_name = values.establishment_name;
    data.rawData.first_name = values.first_name;
    data.rawData.last_name = values.last_name;
    data.rawData.position = values.position;
    data.rawData.phone = values.phone;
    data.rawData.email = values.email;
    data.rawData.setup_person_first_name = values.primary_account_first_name;
    data.rawData.setup_person_last_name = values.primary_account_last_name;
    data.rawData.setup_person_email = values.primary_account_email;
    data.rawData.notes = values.notes;

    data.rawData.paymentType = paymentType;
    data.rawData.bank_account_holder = values.bank_account_holder;
    data.rawData.bank_account_number = values.bank_account_number;
    data.rawData.bank_routing_number = values.bank_routing_number;
    data.rawData.bank_account_type = values.bank_account_type;
    data.rawData.card_name = values.card_name;
    data.rawData.card_expiration_month = values.card_expiration_month;
    data.rawData.card_expiration_year = values.card_expiration_year;
    data.rawData.card_ccv = values.card_ccv;
    data.rawData.card_zipcode = values.card_zipcode;
    data.rawData.card_number = values.card_number;
    data.rawData.ip_address = ip;
    data.rawData.installationWeek = `${format(installWindowStartDate, 'MM/dd/yyyy')} - ${format(
      installWindowEndDate,
      'MM/dd/yyyy',
    )}`;
    data.rawData.tap_count = values.tap_count;
    data.rawData.isAddressDifferent = isAddressDifferent;
    data.rawData.hasHighABV = hasHighABV;
    data.rawData.stainlessLineCount = stainlessLineCount;
    data.rawData.hasMultipleCoolers = hasMultipleCoolers;
    data.rawData.coolerArr = [];
    if (coolerCount > 0 && hasMultipleCoolers) {
      for (let i = 0; i < coolerCount; i++) {
        const name = values[`cooler_${i}_name`];
        const lineCount = values[`cooler_${i}_linecount`];

        data.rawData.coolerArr.push({
          name,
          lineCount,
        });
      }
    }
    data.rawData.pos = values.selected_pos === 'Other' ? values.custom_pos : values.selected_pos;
    data.rawData.initialCost = initialCost;
    data.rawData.subscriptionCost = subscriptionCost;
    data.rawData.bill_cycle_frequency = values.bill_cycle_frequency;
    data.rawData.costPerLine = lineCost === 'CUSTOM' ? `$${customLineCost}` : `$${lineCost}`;
    data.rawData.trainingCost =
      trainingCost === 'CUSTOM' ? `$${customTrainingCost}` : `$${trainingCost}`;
    data.rawData.paymentType = paymentType;
    data.rawData.nameOnPayment = values.card_name || values.bank_account_holder || '';
    data.rawData.hasAcceptedEULA = hasAcceptedEULA;
    data.rawData.hasAcceptedTerms = hasAcceptedTerms;
    data.rawData.hasAcceptedSubcriptionDetails = hasAcceptedSubcriptionDetails;

    data.general['Establishment Name: '] = values.establishment_name;
    data.general['Street Address: '] = values.address;
    data.general['City: '] = values.city;
    data.general['State: '] = values.state;
    data.general['Zipcode: '] = values.zipcode;
    data.general['First name: '] = values.first_name;
    data.general['Last name: '] = values.last_name;
    data.general['Job title: '] = values.position;
    data.general['Phone: '] = values.phone;
    data.general['Email: '] = values.email;
    data.general['Notes: '] = values.notes;

    data.details['Number of lines: '] = values.tap_count;
    data.details['Billing address is different: '] = boolToEnglish(isAddressDifferent);
    data.details['Has high ABV lines: '] = boolToEnglish(hasHighABV);
    data.details['Needs stainless lines: '] = boolToEnglish(Boolean(stainlessLineCount));
    data.details['Number of stainless lines: '] = stainlessLineCount;

    data.installation['Installation week: '] = `${format(
      installWindowStartDate,
      'MM/dd/yyyy',
    )} - ${format(installWindowEndDate, 'MM/dd/yyyy')}`;

    data.coolers['Has multiple coolers: '] = boolToEnglish(hasMultipleCoolers);
    data.coolers.coolerArr = [];
    if (coolerCount > 0 && hasMultipleCoolers) {
      for (let i = 0; i < coolerCount; i++) {
        const name = values[`cooler_${i}_name`];
        const lineCount = values[`cooler_${i}_linecount`];

        data.coolers.coolerArr.push({
          name,
          lineCount,
        });
      }
    }

    data['POS Information']['Selected POS: '] =
    (selectedPackage === 'BASIC') ? 'N/A' : values.selected_pos === 'Other' ? values.custom_pos : values.selected_pos;
    data['POS Information'][`${billFrequency} POS Fee: `] = (selectedPackage === 'BASIC') ? 'N/A' : hasCustomPOSPricing ? `$${Number(customPOSCost).toFixed(2)}` : `$${POS_COST[billFrequency]}`;
    
    data['Billing Information']['Plan: '] = selectedPackage === 'PREMIUM' ? 'Premium' : 'Basic';
    data['Billing Information']['Pricing Plan: '] = lineCost === 'CUSTOM' ? 'Custom': 'Pre-Defined';
    data['Billing Information']['Initial Cost: '] = `$${initialCost.toFixed(2)}`;
    data['Billing Information'][`${billFrequency} Cost: `] = `$${subscriptionCost.toFixed(2)}`;
    data['Billing Information']['Billing Frequency: '] = values.bill_cycle_frequency;
    data['Billing Information']['Cost per Line: '] = lineCost === 'CUSTOM' ? `$${Number(customLineCost).toFixed(2)}` : `$${lineCost.toFixed(2)}`;
    data['Billing Information']['Equipment License Fee Rate: '] =
      trainingCost === 'CUSTOM' ? `$${Number(customTrainingCost).toFixed(2)}` : `$${trainingCost.toFixed(2)}`;

    data.payment['Payment type: '] =
      paymentType === 'ach' ? 'Bank' : paymentType === 'card' ? 'Card' : 'N/A';
    data.payment['Name on payment: '] = values.card_name || values.bank_account_holder || 'N/A';

    data.training['Training person first name: '] = hasDifferentUser
      ? values.primary_account_first_name
      : values.first_name;
    data.training['Training person last name: '] = hasDifferentUser
      ? values.primary_account_last_name
      : values.last_name;
    data.training['Training person email: '] = hasDifferentUser
      ? values.primary_account_email
      : values.email;

    data['Customer Agreement Information']['Has accepted EULA: '] = boolToEnglish(hasAcceptedEULA);
    data['Customer Agreement Information']['Has accepted Key Subscription Terms: '] = boolToEnglish(hasAcceptedTerms);
    data['Customer Agreement Information']['Has accepted Contract Term: '] = boolToEnglish(hasAcceptedSubcriptionDetails);
    data['Customer Agreement Information']['Device IP Address: '] = ip;

    const response = await api.createAccount(data);
    const { message } = response.data;
    if (message === 'Account successfully created!') {
      setShowSuccess(true);
    } else if (
      message === 'Failed validation.' ||
      message === 'Payment method declined.' ||
      message === 'Address invalid.'
    ) {
      enqueueSnackbar(message, { variant: 'warning' });
    } else {
      enqueueSnackbar(message, { variant: 'error' });
    }

    setIsSubmitting(false);
  };

  const handleOpenDialog = useCallback(
    (e, value) => {
      e.preventDefault();
      setOpen(true);
      setCurrentAgreement(value);
    },
    [setOpen, setCurrentAgreement],
  );

  const handleCloseMeetingDialog = useCallback(() => {
    setOpenMeeting(false);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
    setCurrentAgreement(null);
  }, []);

  const handleAcceptAgreement = useCallback(() => {
    currentAgreement === 'eula' ? setHasAcceptedEULA(true) : setHasAcceptedTerms(true);
    setOpen(false);
    setCurrentAgreement(null);
  }, [currentAgreement]);

  const handleChange = useCallback((setterFn, value) => {
    setterFn(value);
  }, []);

  const handleRadioChange = useCallback(
    plan => {
      if (plan !== 'CUSTOM') {
        setCustomLineCost(null)
        setCustomTrainingCost(null)
      } 
      
      const trainingFee = PLANS[selectedPackage][plan].training_fee;
      const lineCost = PLANS[selectedPackage][plan].per_line[billFrequency];

      setTrainingCost(trainingFee);
      setLineCost(lineCost);
    },
    [selectedPackage, billFrequency],
  );

  const POSPriceText = useMemo(() => {
    const suffix = billFrequency === 'Monthly' ? '/mo' : '/yr';
    const cost = POS_COST[billFrequency]
    
    return <>+ ${cost + suffix}</>
  }, [billFrequency]);

  const PriceBox = useMemo(() => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card className={classes.costCard}>
            <CardHeader
              title="Initial Cost *"
              titleTypographyProps={{ align: 'center' }}
              subheaderTypographyProps={{
                align: 'center',
                color: '#676666',
              }}
            />
            <CardContent style={{ textAlign: 'center' }}>
              <Typography variant="h2">{selectedPackage && initialCost ? `$${initialCost.toFixed(2)}` : '-'}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.costCard}>
            <CardHeader
              title={`${billFrequency ===  'Monthly' ? 'Monthly' : 'Annual'} Cost *`}
              titleTypographyProps={{ align: 'center' }}
              subheaderTypographyProps={{
                align: 'center',
                color: '#676666',
              }}
            />
            <CardContent style={{ textAlign: 'center' }}>
              <Typography variant="h2">{selectedPackage && subscriptionCost ? `$${subscriptionCost.toFixed(2)}` : '-'}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card raised className={classes.active}>
            <CardContent>* This amount excludes any applicable taxes</CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }, [classes.costCard, classes.active, selectedPackage, initialCost, billFrequency, subscriptionCost]);

  const frequencySuffix = useMemo(() => {
    return billFrequency === 'Monthly' ? '/mo' : '/yr';
  }, [billFrequency]);

  const PlanOptions = useMemo(() => {
    if (!selectedPackage) return;

    const MenuItem = ({ plan }) => {
      if (plan === 'CUSTOM') {
        return <Typography variant="body1">Select for custom pricing...</Typography>;
      } else {
        return (
          <Box py={1}>
            <Typography variant="body1">
              License Fee per Line:{' '}
              <b>
                ${PLANS[selectedPackage][plan].training_fee}
                /yr
              </b>
            </Typography>
            <Typography>
              Subscription per Line:{' '}
              {!billFrequency ? '-' : ( <b>
                ${PLANS[selectedPackage][plan].per_line[billFrequency]}
                {frequencySuffix}
              </b>)}
            </Typography>
          </Box>
        );
      }
    };

    return (
      <Box>
        <FormLabel component="p">{`Choose one of the following pricing ${selectedPackage.toLowerCase()} plans:`}</FormLabel>
        <RadioGroup>
          {Object.keys(PLANS[selectedPackage]).map(plan => (
            <FormControlLabel
              value={plan}
              control={<Radio color="primary" />}
              label={<MenuItem plan={plan} />}
              onChange={() => handleRadioChange(plan)}
              disabled={!billFrequency}
            />
          ))}
        </RadioGroup>

        {trainingCost === 'CUSTOM' && (
          <Box pt={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>  
                <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-license-fee">License Fee per Line *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-license-fee"
                    labelWidth={150}
                    value={customTrainingCost}
                    onChange={event => handleChange(setCustomTrainingCost, event.target.value)}
                    onBlur={event => handleChange(setCustomTrainingCost, event.target.value)}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    inputRef={register}                 
                  />                  
                </FormControl>
              </Grid>
              <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-subscription-fee">Subscription per Line *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-subscription-fee"
                    labelWidth={150}
                    value={customLineCost}
                    onChange={event => handleChange(setCustomLineCost, event.target.value)}
                    onBlur={event => handleChange(setCustomLineCost, event.target.value)}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    endAdornment={<InputAdornment position="end">{billFrequency === 'Monthly' ? '/ mo' : '/ yr'}</InputAdornment>}   
                    inputRef={register}                    
                  />                  
                </FormControl>                
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    );
  }, [
    selectedPackage,
    handleChange,
    trainingCost,
    customLineCost,
    customTrainingCost,
    handleRadioChange,
    register,
    billFrequency,
    frequencySuffix,
  ]);

  const POSForm = useMemo(() => {
    return (
      <Box py={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <FormLabel component="div">
              <p>Select your POS:</p>
              {!hasCustomPOSPricing && <label className={classes.label}>{POSPriceText}</label>}
            </FormLabel>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="pos-select"
              name="selected_pos"
              options={SUPPORTED_POS_LIST}
              onChange={(event, value) => handleChange(setCurrentPOS, value)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Choose a POS..."
                  name="selected_pos"
                  inputRef={register}
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          {currentPOS === 'Other' && (
            <>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextField
                  label="Please enter your POS..."
                  name="custom_pos"
                  inputRef={register({ required: true })}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    );
  }, [hasCustomPOSPricing, classes.label, POSPriceText, currentPOS, register, handleChange]);

  const ShippingLine = useMemo(() => {
    return (
      <FormLabel component="div">
        <p>Shipping & Handling</p>
        <label className={classes.label}>+ ${SHIPPING_HANDLING_COST}/line</label>
      </FormLabel>
    );
  }, [classes.label]);

  const PayLaterMessage = useMemo(
    () => (
      <Card raised className={classes.active}>
        <CardContent>
          One of our sales representatives will reach out to collect your payment information.
        </CardContent>
      </Card>
    ),
    [classes.active],
  );

  const ACHForm = useMemo(() => {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <TextField
            name="bank_account_holder"
            label="Account Holder"
            variant="outlined"
            fullWidth
            inputRef={register}
            helperText={errors.name_on_account}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="bank_account_number"
            label="Account Number"
            variant="outlined"
            fullWidth
            inputRef={register}
            helperText={errors.bank_account_number}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="bank_routing_number"
            label="Routing Number"
            variant="outlined"
            fullWidth
            inputRef={register}
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel component="p">Select an account type:</FormLabel>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="bank_account_type-select"
            options={['Savings', 'Checking']}
            renderInput={params => (
              <TextField
                {...params}
                label="Select..."
                name="bank_account_type"
                inputRef={register}
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    );
  }, [register, errors]);

  const CardForm = useMemo(() => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Name on Card"
            name="card_name"
            variant="outlined"
            inputRef={register}
            fullWidth
            helperText={errors.card_name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Card Number"
            name="card_number"
            variant="outlined"
            inputRef={register}
            fullWidth
            helperText={errors.card_number}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="CCV"
            name="card_ccv"
            variant="outlined"
            inputRef={register}
            fullWidth
            helperText={errors.card_ccv}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Month"
            name="card_expiration_month"
            variant="outlined"
            inputRef={register}
            fullWidth
            helperText={errors.card_expiration_month}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Year"
            name="card_expiration_year"
            variant="outlined"
            inputRef={register}
            fullWidth
            helperText={errors.card_expiration_year}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="ZIP"
            name="card_zipcode"
            inputRef={register}
            variant="outlined"
            fullWidth
            helperText={errors.card_zipcode}
          />
        </Grid>
      </Grid>
    );
  }, [register, errors]);

  const handleChangeInstallWindow = useCallback(({ start, end }) => {
    setInstallWindowStartDate(start);
    setInstallWindowEndDate(end);
  }, []);

  const calculatePOS = useCallback(() => {
    if (selectedPackage === 'PREMIUM') {
      if (hasCustomPOSPricing) {
        return customPOSCost ? Number(customPOSCost) : 0
      } else {
        return POS_COST[billFrequency]
      }
    } else {
      return 0
    }
  }, [billFrequency, customPOSCost, hasCustomPOSPricing, selectedPackage])

  const calculateAnnual = useCallback(() => {
    const posCost = calculatePOS();
    const tapCost = Number(tapCount) * Number(customLineCost || lineCost);
  
    return tapCost + posCost;
  }, [calculatePOS, customLineCost, lineCost, tapCount]);

  const calculateMonthly = useCallback(() => {
    const posCost = calculatePOS();
    const tapCost = tapCount * Number(customLineCost || lineCost);

    return posCost + tapCost;
  }, [calculatePOS, customLineCost, lineCost, tapCount]);

  const calculateGateway = useCallback(() => {
    const coolerCount = Math.ceil(tapCount / MAX_LINES_PER_COOLER)
    return coolerCount * GW_COST
  }, [tapCount])


  const calculateInitial = useCallback(() => {
      const gatewayCost = calculateGateway();
      const shippingCost = Number(tapCount) * SHIPPING_HANDLING_COST;
      const licenseCost = Number(customTrainingCost || trainingCost) * tapCount;

      return licenseCost + gatewayCost + shippingCost;
    },
    [calculateGateway, tapCount, customTrainingCost, trainingCost],
  );

  useEffect(() => {
    setInitialCost(calculateInitial());
   if (billFrequency === "Monthly") {
    setSubscriptionCost(calculateMonthly());
   } else {
    setSubscriptionCost(calculateAnnual());
   }
  }, [
    getValues,
    trainingCost,
    lineCost,
    tapCount,
    coolerCount,
    selectedPackage,
    customLineCost,
    customTrainingCost,
    customPOSCost,
    hasCustomPOSPricing,
    billFrequency,
    calculateAnnual,
    calculateMonthly,
    calculateInitial
  ]);

  if (showSuccess) return <Success />;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Box pt={2} className={classes.heading}>
        <Typography variant="h1">Registration Portal</Typography>
        <Divider />
      </Box>
      <Box pt={2}>
        <TextField
          label="Location Name"
          required
          name="establishment_name"
          inputRef={register({ required: true })}
          variant="outlined"
          fullWidth
          helperText={errors.establishment_name && 'Location name is required!'}
          className={classes.lessMargin}
        />
        <Box pb={4}>
          <label className={classes.label}>
            Please enter the name of the location you are registering - if you are registering more than one,
            you will need to complete additional registration forms for each location.
          </label>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="First Name"
            required
            variant="outlined"
            inputRef={register({ required: true })}
            name="first_name"
            fullWidth
            helperText={errors.first_name && 'Your first name is required!'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Last Name"
            required
            variant="outlined"
            inputRef={register({ required: true })}
            name="last_name"
            fullWidth
            helperText={errors.last_name && 'Your last name is required!'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Position"
            required
            variant="outlined"
            inputRef={register({ required: true })}
            name="position"
            helperText={errors.position && 'Job position is required!'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Email"
            required
            variant="outlined"
            inputRef={register({
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              },
            })}
            name="email"
            fullWidth
            helperText={errors.email && 'Invalid email address!'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Phone"
            required
            variant="outlined"
            name="phone"
            fullWidth
            inputRef={register({ required: true })}
            helperText={errors.phone && 'Invalid phone number!'}
          />
        </Grid>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Checkbox
            checked={hasDifferentUser}
            onClick={event => handleChange(setHasDifferentUser, !hasDifferentUser)}
            color="primary"
          />
          <Typography className={classes.statement}>
            Someone else will be setting up this establishment
          </Typography>
        </Box>
        {hasDifferentUser && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                label="First Name"
                required
                variant="outlined"
                inputRef={register}
                name="primary_account_first_name"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Last Name"
                required
                variant="outlined"
                inputRef={register}
                name="primary_account_last_name"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                required
                variant="outlined"
                inputRef={register}
                name="primary_account_email"
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <Box py={2}>
            <Typography variant="h4">Billing Address</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Street Address"
            required
            variant="outlined"
            inputRef={register({ required: true })}
            name="address"
            helperText={errors.address && 'Billing address cannot be blank!'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="City"
            required
            variant="outlined"
            inputRef={register({ required: true })}
            name="city"
            helperText={errors.city && 'City cannot be blank!'}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="State"
            required
            variant="outlined"
            inputRef={register({ required: true })}
            name="state"
            helperText={errors.state && 'State cannot be blank!'}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label="ZIP"
            required
            variant="outlined"
            inputRef={register({ required: true })}
            name="zipcode"
            helperText={errors.zipcode && 'ZIP cannot be blank!'}
          />
        </Grid>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Checkbox
            checked={isAddressDifferent}
            onClick={event => handleChange(setIsAddressDifferent, !isAddressDifferent)}
            color="primary"
          />
          <Typography className={classes.statement}>Use different address for shipping</Typography>
        </Box>
      </Grid>
      {isAddressDifferent && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box py={2}>
              <Typography variant="h4">Shipping Address</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Street Address"
              variant="outlined"
              inputRef={register}
              name="shipping_address"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="City" variant="outlined" inputRef={register} name="shipping_city" />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField label="State" variant="outlined" inputRef={register} name="shipping_state" />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField label="ZIP" variant="outlined" inputRef={register} name="shipping_zipcode" />
          </Grid>
        </Grid>
      )}
      <Box>
        <Box pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">How many beer lines do you have: *</Typography>
          <Typography variant="h4">{tapCount}</Typography>
        </Box>
        <Box>
          <Controller
            name="tap_count"
            control={control}
            render={props => (
              <Slider
                {...props}
                key="tap-slider"
                min={1}
                defaultValue={tapCount}
                step={1}
                max={250}
                valueLabelDisplay={'auto'}
                onChange={(event, value) => props.onChange(value)}
                onChangeCommitted={(event, value) => handleChange(setTapCount, value)}
                ValueLabelComponent={ValueLabelComponent}
                className={classes.slider}
                inputRef={register}
              />
            )}
          />
        </Box>
      </Box>
      <Box pt={2}>
        <Grid container>
          <Grid container item xs={12} alignItems="center">
            <Box pt={2}>
              <FormLabel component="p">Do you have more than one cooler? *</FormLabel>
            </Box>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Grid item>
              <RadioGroup
                name="has_multiple_coolers"
                onChange={event =>
                  handleChange(setHasMultipleCoolers, event.target.value === 'yes' ? true : false)
                }
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label={
                    <Typography className={classes.excerpt}>
                      Yes
                    </Typography>
                  }
                />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">How many coolers do you have:</Typography>
            <Typography variant="h4">{coolerCount}</Typography>
          </Box>
          <Box>
            <Controller
              name="cooler_count"
              control={control}
              render={props => (
                <Slider
                  {...props}
                  key="tap-slider"
                  min={1}
                  defaultValue={coolerCount}
                  step={1}
                  max={20}
                  disabled={!hasMultipleCoolers}
                  valueLabelDisplay={'auto'}
                  onChange={(event, value) => props.onChange(value)}
                  onChangeCommitted={(event, value) => handleChange(setCoolerCount, value)}
                  ValueLabelComponent={ValueLabelComponent}
                  className={classes.slider}
                  inputRef={register}
                />
              )}
            />
          </Box>
        </Box>
        <Grid container>
          <Grid container item xs={12} alignItems="center">
            <Box pt={2}>
              <FormLabel component="p">
                Do you currently, or plan to, pour draft beverages with an ABV greater than or equal to 15%?
              </FormLabel>
            </Box>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Grid item>
              <RadioGroup
                name="has_high_abv"
                onChange={event =>
                  handleChange(setHasHighABV, event.target.value === 'yes' ? true : false)
                }
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label={<Typography className={classes.excerpt}>Yes</Typography>}
                />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        {hasHighABV && (
          <Box pt={4}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">How many lines will do this:</Typography>
              <Typography variant="h4">{stainlessLineCount}</Typography>
            </Box>
            <Box>
              <Controller
                name="stainless_line_count"
                control={control}
                render={props => (
                  <Slider
                    {...props}
                    key="stainless-line-slider"
                    min={1}
                    defaultValue={stainlessLineCount}
                    step={1}
                    max={20}
                    valueLabelDisplay={'auto'}
                    onChange={(event, value) => props.onChange(value)}
                    onChangeCommitted={(event, value) => handleChange(setStainlessLineCount, value)}
                    ValueLabelComponent={ValueLabelComponent}
                    className={classes.slider}
                    inputRef={register}
                  />
                )}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box py={2}>
        <Divider />
        <Typography variant="h4">Installation Details</Typography>
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <FormLabel component="p">Select installation week: *</FormLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <WeekPicker value={installWindowStartDate} onChange={handleChangeInstallWindow} />
        </Grid>
        <Grid item xs={12}>
          <Card raised className={classes.active}>
            <CardContent>
              * Billing for Subscription Fee will begin two weeks after Installation Date. 
              
              As such, it is imperative installation and training dates are maintained 
              and completed before that date - for items such as missed training and 
              installations, additional fees may be charged.
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box py={2}>
        <Divider />
        <Typography variant="h4">Select a Plan</Typography>
      </Box>
      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card raised className={clsx({ [classes.active]: selectedPackage === 'BASIC' })}>
              <CardHeader
                title="Basic"
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{
                  align: 'center',
                }}
              />
              <CardContent style={{ textAlign: 'center' }}>
                Standard Volume-Only Reports w/ no POS integration
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => handleChange(setSelectedPackage, 'BASIC')}
                >
                  {selectedPackage !== 'BASIC' ? 'Select plan' : 'Selected'}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card raised className={{ [classes.active]: selectedPackage === 'PREMIUM' }}>
              <CardHeader
                title="Premium"
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{
                  align: 'center',
                  color: '#676666',
                }}
              />
              <CardContent style={{ textAlign: 'center' }}>Enhanced Analytical Reports w/ POS Integration</CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => handleChange(setSelectedPackage, 'PREMIUM')}
                >
                  {selectedPackage !== 'PREMIUM' ? 'Select plan' : 'Selected'}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <FormLabel component="p">What is your preferred Billing Frequency? *</FormLabel>
        <Controller
          render={({ onChange, value, name }) => (
            <RadioGroup
              name={name}
              onChange={e => {
                const { value } = e.target;
                setBillFrequency(value);
                onChange(value);
              }}
              value={value}
            >
              <FormControlLabel
                value="Monthly"
                control={<Radio color="primary" />}
                label="Monthly"
              />
              <FormControlLabel
                value="Yearly"
                control={<Radio color="primary" />}
                label="Annual (discount)"
              />
            </RadioGroup>
          )}
          name="bill_cycle_frequency"
          control={control}
        />
      </Box>
      <Box>{selectedPackage && PlanOptions}</Box>
      {lineCost === 'CUSTOM' && trainingCost === 'CUSTOM' && billFrequency !== 'Monthly' && (<Box pt={2}> 
        <Card raised className={classes.active}>
          <CardContent>* Discount is not applied when using custom pricing</CardContent>
        </Card>
      </Box>)}
      <Box>{selectedPackage === 'PREMIUM' && POSForm}</Box>
      {selectedPackage === 'PREMIUM' && (
        <Box py={2}>
          <Grid container>
            <Grid item xs={12}>
              <Box pb={2} display="flex" flexDirection="row" alignItems="center">
                <Checkbox
                  checked={hasCustomPOSPricing}
                  onClick={event => handleChange(setHasCustomPOSPricing, !hasCustomPOSPricing)}
                  color="primary"
                />
                <Typography className={classes.statement}>Use custom POS pricing</Typography>
              </Box>
            </Grid>
            {hasCustomPOSPricing && (
              <Grid item xs={12}>
                <Box>
                  <TextField
                    label={`Enter ${billFrequency === 'Monthly' ? 'a monthly' : 'an annual'} POS fee`}
                    variant="outlined"
                    value={customPOSCost}
                    onChange={event => handleChange(setCustomPOSCost, event.target.value)}
                    onBlur={event => handleChange(setCustomPOSCost, event.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    fullWidth
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      <Box pt={2}>{ShippingLine}</Box>
      <Box>
        <Divider />
        {PriceBox}
      </Box>
      <Box py={2}>
        <Divider />
        <Typography variant="h4">Transaction Details</Typography>
      </Box>
      <Box>
        {!referral ? (
          <Autocomplete
            id="referral-select"
            freeSolo
            name="referral"
            options={[
              'Austin Gibson',
              'Brett Danielson',
              'Fuz Kamal',
              'Marcus Sims',
              'Nathan Downs',
              'Orest Mryszuk',
            ]}
            renderInput={params => (
              <TextField
                {...params}
                label="Who referred you?"
                name="referral"
                inputRef={register}
                margin="normal"
                variant="outlined"
              />
            )}
          />
        ) : (
          <TextField
            label="Referral"
            variant="outlined"
            inputRef={register}
            value={referral}
            fullWidth
            disabled
          />
        )}
      </Box>
      <Box>
        <Box>
          <FormLabel component="p">Select preferred payment method: *</FormLabel>
          <RadioGroup
            name="subscription_frequency"
            onChange={event => handleChange(setPaymentType, event.target.value)}
          >
            <FormControlLabel
              value="ach"
              control={<Radio color="primary" />}
              label="ACH / Bank Account"
            />
            <FormControlLabel
              value="card"
              control={<Radio color="primary" />}
              label="Credit Card"
            />
            <FormControlLabel
              value="Pay later"
              control={<Radio color="primary" />}
              label="Pay Later"
            />
          </RadioGroup>
        </Box>
        {paymentType === 'ach' && <Box py={2}>{ACHForm}</Box>}
        {paymentType === 'card' && <Box py={2}>{CardForm}</Box>}
        {paymentType === 'Pay later' && <Box py={2}>{PayLaterMessage}</Box>}
      </Box>
      <Box py={2}>
        <Divider />
        <Typography variant="h4">Notes</Typography>
      </Box>
      <Box pt={2}>
        <TextField
          rows={5}
          multiline
          variant="outlined"
          name="notes"
          inputRef={register}
          label="Start typing..."
        />
      </Box>
      <Divider />
      <Box display="flex" flexDirection="row" alignItems="center">
        <Checkbox
          checked={hasAcceptedTerms}
          onClick={event => handleOpenDialog(event, 'key-terms')}
          color="primary"
        />
        <Typography className={classes.statement}>
          I have read and agree to BarTrack's{' '}
          <span onClick={event => handleOpenDialog(event, 'key-terms')}>Key Subscription Terms</span>.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Checkbox
          checked={hasAcceptedEULA}
          onClick={event => handleOpenDialog(event, 'eula')}
          color="primary"
        />
        <Typography className={classes.statement}>
          I have read and agree to BarTrack's{' '}
          <span onClick={event => handleOpenDialog(event, 'eula')}>
            End User Subscription Agreement.
          </span>
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Checkbox
          checked={hasAcceptedSubcriptionDetails}
          onClick={() => setHasAcceptedSubcriptionDetails(prev => !prev)}
          color="primary"
        />
        <Typography className={classes.statement}>
          I confirm that I agree to a two year contract term upon activation of the system. Refer to
          End User Subscription Agreement for further details on contract terms.
        </Typography>
      </Box>
      <Box py={4} className={classes.submit}>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          {isSubmitting ? <CircularProgress size={20} /> : 'Submit'}
        </Button>
      </Box>
      {open && (
        <AgreementDialog
          open={open}
          agreement={currentAgreement}
          onClose={handleCloseDialog}
          onAccept={handleAcceptAgreement}
        />
      )}
      <MeetingDialog open={openMeeting} onClose={handleCloseMeetingDialog} />
    </form>
  );
}

export default PaymentForm;
