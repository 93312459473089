import { fade } from '@material-ui/core';

export default {
  root: {
    backgroundImage: `linear-gradient(214.77deg, ${fade('#292929', 0.3)} 0.48%, ${fade(
      'rgba(41, 41, 41, 0.5)',
      0.3,
    )} 100%)`,
  },
};
