import axios from 'axios';
import qs from 'qs';

const injectStringParams = (str, params = {}) => {
  return str.replace(/:(\w+)/g, (match, param) => params[param]);
};

const bartrackClient = axios.create({
  baseURL: `${'https://api.bartrack.beer:443/api'}`,
  // baseURL: `${'https://api-dev.bartrack.beer:443/api'}`,
  // baseURL: 'https://api-local.bartrack.beer/api',
  timeout: 2 * 60 * 1000,
});

bartrackClient.interceptors.request.use(config => {
  config.paramsSerializer = paramsSerializer;
  if (config.requestParams) {
    config.url = injectStringParams(config.url, config.requestParams);
  }
  return config;
});

bartrackClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export default bartrackClient;

const paramsSerializer = params => {
  return qs.stringify(params, { indices: false });
};
