export default {
  root: {
    transition: '.3s',
    color: 'rgba(218, 218, 218, .3)',
    '&:hover': {
      color: 'rgba(218, 218, 218, .5)',
      backgroundColor: 'rgba(255,122,0,.3)',
    },
  },
  colorPrimary: {
    '&$disabled': {
      color: 'rgba(218, 218, 218, .10)',
    },
  },
};
