export const PLANS = {
  PREMIUM: {
    1: {
      training_fee: 199.99,
      per_line: {
        Monthly: 14.99,
        Yearly: 149.99,
      },
    },
    2: {
      training_fee: 149.99,
      per_line: {
        Monthly: 17.99,
        Yearly: 199.99,
      },
    },
    3: {
      training_fee: 99.99,
      per_line: {
        Monthly: 19.99,
        Yearly: 219.99,
      },
    },
    4: {
      training_fee: 49.99,
      per_line: {
        Monthly: 24.49,
        Yearly: 249.99,
      },
    },
    CUSTOM: { 
      training_fee: 'CUSTOM', 
      per_line: {
        Monthly: 'CUSTOM',
        Yearly: 'CUSTOM'
      } 
    },
  },
  BASIC: {
    BAS_1: {
      training_fee: 199.99,
      per_line: {
        Monthly: 6.99,
        Yearly: 79.99,
      },
    },
    BAS_2: {
      training_fee: 149.99,
      per_line: {
        Monthly: 12.49,
        Yearly: 124.99,
      },
    },
    BAS_3: {
      training_fee: 99.99,
      per_line: {
        Monthly: 17.49,
        Yearly: 199.99,
      },
    },
    BAS_4: {
      training_fee: 49.99,
      per_line: {
        Monthly: 22.49,
        Yearly: 239.99,
      },
    },
    CUSTOM: { 
      training_fee: 'CUSTOM', 
      per_line: {
        Monthly: 'CUSTOM',
        Yearly: 'CUSTOM'
      }  
    },
  },
};

export const POS_COST = {
  Monthly: 29.99,
  Yearly: 359.88,
};

export const SHIPPING_HANDLING_COST = 9.99;

export const GW_COST = 299.99;

export const MAX_LINES_PER_COOLER = 24