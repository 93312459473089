export default {
  root: {
    padding: '16px 16px 24px',
    justifyContent: 'center',
    '& button': {
      margin: '0 8px',
    },
    '&.pt-0': {
      paddingTop: 0,
    },
  },
};
