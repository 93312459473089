import palette from 'theme/palette';

export default {
  root: {
    color: palette.white,
    minHeight: 60,
  },
  indicator: {
    display: 'none',
  },
};
