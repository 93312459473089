import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core'

import mixins from 'theme/mixins'
import palette from 'theme/palette'
import typography from 'theme/typography'
import breakpoints from 'theme/breakpoints'
import props from 'theme/props'
import * as overrides from 'theme/overrides';

const theme = createMuiTheme({
  breakpoints,
  overrides,
  mixins,
  palette,
  typography,
  props,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
})

export default theme
