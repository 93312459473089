import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  root: {
    backgroundColor: fade(palette.secondary.lightest, 0.05),
    '&:nth-of-type(odd)': {
      backgroundColor: fade(palette.secondary.lightest, 0.1),
    },
  },
  head: {
    backgroundColor: 'transparent !important',
  },
};
