export default {
  root: {
    lineHeight: 0.7,
    color: 'rgba(255,255,255, .6)',
    '&$focused': {
      lineHeight: 1.2,
      color: 'rgba(255,255,255, .6)',
    },
    '&$disabled': {
      color: 'rgba(255,255,255, .2)',
    },
    '&.MuiFormLabel-filled': {
      lineHeight: 1.2,
    },
  },
};
