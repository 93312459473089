import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  root: {
    color: 'inherit',
    padding: 2,
    '&$disabled': {
      opacity: 0.4,
    },
    '& .MuiTouchRipple-root': {
      transition: '0.3s',
    },
    '&:hover': {
      '& .MuiTouchRipple-root': {
        backgroundColor: fade(palette.white, 0.3),
      },
    },
    '&.active': {
      color: `${palette.primary.main} !important`,
    },
    '&.confirm': {
      '&:focus, &:hover': {
        color: palette.primary.main,
      },
    },
  },
  edgeStart: {
    marginLeft: -20,
    padding: 12,
  },
};
