import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  ({ palette, spacing, breakpoints, transitions }) => ({
    appBar: {
      position: 'relative',
      borderBottom: `1px solid ${palette.divider}`,
    },
    main: {
      marginBottom: spacing(4),
    },
    paper: {
      marginTop: spacing(3),
      marginBottom: spacing(3),
      padding: spacing(2),
      [breakpoints.up('md')]: {
        marginTop: spacing(6),
        marginBottom: spacing(6),
        padding: spacing(3),
      },
    },
    stepper: {
      padding: spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: spacing(3),
      marginLeft: spacing(1),
    },
  })
)

export default useStyles
