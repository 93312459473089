export default {
  root: {
    position: 'absolute',
    left: 5,
    top: '100%',
  },
  contained: {
    marginLeft: 0,
    marginRight: 0,
  },
};
