export default {
  underline: {
    lineHeight: 1.3,
    '&:before, &:after': {
      display: 'none',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingLeft: 12,
    },
    '& .MuiInputBase-input': {
      padding: '6px 0',
    },
    '& .MuiSelect-icon': {
      color: 'inherit',
      fontSize: 18,
      top: 'calc(50% - 10px)',
    },
  },
};
