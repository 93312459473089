export default {
  root: {
    width: '100%',
    marginBottom: 32,
    '&.mb-0': {
      marginBottom: 0,
    },
    '&.hidden': {
      display: 'none',
    },
  },
};
