const SUPPORTED_POS_LIST = [
  'Aloha',
  'Arryved',
  'Brink',
  'Clover',
  'Digital Dining',
  'Dinerware',
  'Focus',
  'Foodtronix',
  'Future',
  'HarbourTouch',
  'Heartland',
  'LAVU',
  'LightSpeed',
  "Maitre'D",
  'Micros 3700',
  'Micros Simphony',
  'NorthStar',
  'POSitouch',
  'Restaurant Manager',
  'Revel',
  'SmartTab',
  'Spot-On',
  'Square',
  'Squirrel',
  'Toast',
  'UpServe',
  'XPIENT',
];

export default SUPPORTED_POS_LIST;
