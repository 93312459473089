import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  label: {
    '&$disabled': {
      color: fade(palette.white, 0.6),
    },
  },
};
