import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  root: {
    height: '100%',
    color: palette.white,
    borderRadius: 5,
    transition: '0.3s',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.white,
    },
    '&$focused .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.primary.main,
      borderWidth: 1,
    },
    '&$disabled': {
      '& $notchedOutline': {
        borderColor: fade(palette.white, 0.2),
      },
    },
    '&$error': {
      '&:hover, &$focused': {
        '& $notchedOutline': {
          borderColor: palette.error.main,
        },
      },
    },
  },
  notchedOutline: {
    transition: '0.3s',
    borderColor: fade(palette.white, 0.32),
    '&:hover': {
      borderColor: palette.white,
    },
  },
  input: {
    padding: '16px 14px',
  },
};
