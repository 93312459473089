import palette from 'theme/palette';

export default {
  gutterBottom: {
    marginBottom: 10,
  },
  colorSecondary: {
    color: palette.info.main,
  },
};
