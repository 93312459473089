import React from 'react';
import { Box, Typography, Divider, List, ListItem, Button } from '@material-ui/core';

const Success = () => {
  const onRedirect = () => {
    window.open('https://meetings.hubspot.com/amanda-sund');
  };

  return (
    <Box>
      <Box style={{ textAlign: 'center ' }}>
        <Typography variant="h1">Registration Successful</Typography>
      </Box>
      <Divider />
      <Box py={2}>
        <Typography variant="h4">
          You have successfully completed the registration process - click below to schedule a
          meeting with our Onboarding team to discuss next steps for your installation.
        </Typography>
      </Box>
      <Divider />
      <Box>
        <Box pb={1}>
          <Typography variant="h2">Onboarding Overview</Typography>
        </Box>
        <List>
          <ListItem>1. Schedule an installation, training, and first report review.</ListItem>
          <ListItem>2. Connect BarTrack with your POS system (if applicable).</ListItem>
          <ListItem>3. Complete a site survey and beverage pricing sheet.</ListItem>
          <ListItem>4. Review additional pre-install steps as-needed.</ListItem>
        </List>
      </Box>

      <Box py={2} style={{ textAlign: 'center ' }}>
        <Button onClick={onRedirect} color="primary" variant="contained">
          Click to schedule
        </Button>
      </Box>
    </Box>
  );
};

export default Success;
