import palette from 'theme/palette';
import { fade } from '@material-ui/core';

export default {
  root: {
    position: 'relative',
    textAlign: 'center',
    padding: '0 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 56,
    backgroundColor: palette.background.default,
    '& .MuiIconButton-root': {
      color: fade(palette.white, 0.6),
    },
    '& .MuiIconButton-colorPrimary': {
      color: palette.primary.main,
    },
    '@media(max-width: 599px)': {
      padding: '0 16px',
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      zIndex: 1301,
    },
    '@media(min-width: 600px)': {
      background: '#606060',
      height: 44,
    },
  },
};
