import axios from 'axios';
import qs from 'qs';

const injectStringParams = (str, params = {}) => {
  return str.replace(/:(\w+)/g, (match, param) => params[param]);
};

const wordpressClient = axios.create({
  baseURL: 'https://bartrack.beer/wp-json/wp/v2',
  timeout: 2 * 60 * 1000,
});

wordpressClient.interceptors.request.use(config => {
  config.paramsSerializer = paramsSerializer;
  if (config.requestParams) {
    config.url = injectStringParams(config.url, config.requestParams);
  }
  return config;
});

wordpressClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export default wordpressClient;

const paramsSerializer = params => {
  return qs.stringify(params, { indices: false });
};
