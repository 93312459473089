import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  root: {
    borderBottom: '1px solid #1C1C1C',
    padding: '5px 10px',
    height: 46,
  },
  head: {
    color: fade(palette.white, 0.6),
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
};
