import { fade } from '@material-ui/core';
import palette from 'theme/palette';

const radius = 5;

export default {
  root: {
    minWidth: 144,
    minHeight: 36,
    fontSize: 18,
    fontWeight: 500,
    textTransform: 'capitalize',
    border: `1px solid ${fade(palette.white, 0.5)}`,
    '&:first-of-type': {
      borderRadius: `${radius}px 0 0 ${radius}px`,
    },
    '&:last-of-type': {
      borderRadius: `0 ${radius}px ${radius}px 0`,
    },
    '&$selected': {
      color: fade(palette.black, 0.87),
      borderColor: '#FF7A00',
      backgroundSize: '200% auto',
      backgroundImage: 'linear-gradient(to right, #FF7B01 0%, #FFCE44 50%, #FF7B01 100%)',
      '&:hover': {
        backgroundPosition: 'right center',
      },
    },
    '@media(max-width: 599px)': {
      fontSize: 14,
      minWidth: 91,
    },
  },
  textColorInherit: {
    opacity: 0.5,
    transition: '.3s',
    '&:hover': {
      opacity: 1,
    },
  },
};
