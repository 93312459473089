import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  root: {
    height: 36,
    borderRadius: 5,
    minWidth: 105,
    '&.btn--circle': {
      minWidth: 36,
      borderRadius: '50%',
      padding: 0,
    },
  },
  sizeLarge: {
    height: 46,
  },
  label: {
    whiteSpace: 'nowrap',
  },
  text: {
    color: palette.white,
    '&:hover': {
      backgroundColor: fade(palette.white, 0.15),
    },
    '&:hover:focus': {
      backgroundColor: fade(palette.white, 0.3),
    },
    '&$disabled': {
      color: palette.white,
      opacity: 0.3,
    },
    '&.active': {
      color: palette.primary.main,
    },
  },
  outlined: {
    background: 'none',
    borderColor: palette.white,
    color: palette.white,
    '&:hover': {
      backgroundColor: fade(palette.white, 0.15),
    },
    '&:hover:focus': {
      backgroundColor: fade(palette.white, 0.3),
    },
    '&$disabled': {
      borderColor: palette.white,
      color: palette.white,
      opacity: 0.5,
    },
    '&.add': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: 36,
      background: 'linear-gradient(270deg, #312929 0.48%, #292929 100%)',
      borderRadius: 3,
      borderWidth: 1,
      borderStyle: 'dashed',
      borderColor: palette.primary.main,
      '&.add--md': {
        height: 46,
      },
      '&.add--lg': {
        height: 100,
        '@media(max-width: 1023px)': {
          height: 79,
        },
      },
    },
  },
  containedPrimary: {
    transition: '0.5s',
    backgroundSize: '200% auto',
    backgroundImage: 'linear-gradient(to right, #FF7B01 0%, #FFCE44 50%, #FF7B01 100%)',
    '&:hover': {
      backgroundPosition: 'right center',
    },
    '&$disabled': {
      background: 'rgba(255, 255, 255, 0.3)',
      color: palette.black,
    },
  },
};
