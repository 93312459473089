export default {
  MuiSelect: {
    MenuProps: {
      autoFocus: false,
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    },
  },
  MuiMenu: {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  },
  MuiTextField: {
    InputProps: {
      autoComplete: 'off',
    },
  },
}
