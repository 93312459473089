import React from 'react';
import { IconButton, OutlinedInput, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  format,
  isValid,
  startOfWeek,
  endOfWeek,
  isSameDay,
  isWithinInterval,
  addDays,
} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import clsx from 'clsx';
import useStyles from './styles';

const WeekPicker = ({ value, onChange }) => {
  const classes = useStyles();
  const formatWeekSelectLabel = (date, invalidLabel) => {
    let dateClone = new Date(date);

    return dateClone && isValid(dateClone)
      ? `${format(startOfWeek(dateClone), 'MMM do')} - ${format(endOfWeek(dateClone), 'MMM do')}`
      : invalidLabel;
  };

  const fourWeeksInFuture = startOfWeek(addDays(new Date(), 28));

  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth, { props }) => {
    let dateClone = new Date(date);
    let selectedDateClone = new Date(selectedDate);

    const start = startOfWeek(selectedDateClone);
    const end = endOfWeek(selectedDateClone);

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
      [classes.disabledDay]: props.disabled,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  const ToolbarComponent = ({ date }) => {
    let dateClone = new Date(date);
    return (
      <div className={classes.toolbar}>
        <Typography variant="h5">Week Of</Typography>
        <Typography variant="h4">{`${format(startOfWeek(dateClone), 'MMM do')} - ${format(
          endOfWeek(dateClone),
          'MMM do',
        )}`}</Typography>
      </div>
    );
  };

  const TextFieldComponent = props => {
    return <OutlinedInput {...props} />;
  };

  const handleChange = date => {
    onChange({
      start: startOfWeek(new Date(date)),
      end: endOfWeek(new Date(date)),
    });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        value={value}
        onChange={handleChange}
        minDate={fourWeeksInFuture}
        renderDay={renderWrappedWeekDay}
        labelFunc={formatWeekSelectLabel}
        ToolbarComponent={ToolbarComponent}
        TextFieldComponent={TextFieldComponent}
        disablePast
      />
    </MuiPickersUtilsProvider>
  );
};

export default WeekPicker;
