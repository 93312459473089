import bartrackClient from 'services/bartrackClient';
import wordpressClient from 'services/wordpressClient';
import * as api from 'config/routes';

export default {
  createAccount(payload, params) {
    return bartrackClient.post(api.CREATE_ACCOUNT, payload, { params });
  },
  getPages() {
    return wordpressClient.get(api.GET_PAGES);
  },
};
