import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  root: {
    '&:hover': {
      color: palette.white,
    },
    '&$active': {
      color: fade(palette.white, 0.6),
    },
    '& $icon': {
      color: `inherit !important`,
    },
  },
};
