import palette from 'theme/palette';

export default {
  root: {
    position: 'relative',
    cursor: 'pointer',
    '&::after': {
      content: "''",
      width: 0,
      height: 1,
      backgroundColor: palette.white,
      position: 'absolute',
      bottom: -3,
      left: '50%',
      transform: 'translateX(-50%)',
      transition: '.3s',
    },
    '&:hover': {
      '&::after': {
        width: '100%',
      },
    },
  },
  underlineHover: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
};
