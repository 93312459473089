import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  root: {
    color: palette.white,
    borderRadius: 3,
    transition: '0.3s',
    backgroundColor: fade('#EFEFEF', 0.1),
    height: 34,
    textAlign: 'center',
    '&$focused': {
      backgroundColor: fade('#EFEFEF', 0.1),
    },
    '&:hover, &$focused:hover': {
      backgroundColor: fade('#EFEFEF', 0.2),
    },
    '&$disabled, &$disabled:hover': {
      backgroundColor: fade(palette.white, 0.1),
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: `${fade('#EFEFEF', 0.1)} !important`,
      },
      '&$focused:hover': {
        backgroundColor: `${fade('#EFEFEF', 0.2)} !important`,
      },
    },
    '&.number': {
      color: palette.black,
      backgroundColor: palette.secondary.lightest,
      width: 45,
      height: 45,
      fontSize: 22,
      '@media(max-width: 599px)': {
        fontSize: 18,
      },
      fontWeight: 500,
      margin: '0 5px',
      '& input': {
        textAlign: 'center',
        padding: 0,
      },
    },
    '&.square-black': {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: 10,
      paddingRight: 0,
      color: palette.white,
      backgroundColor: fade(palette.white, 0.03),
      borderRadius: 10,
      margin: 0,
      fontWeight: 500,
      width: 75,
      height: 75,
      fontSize: 22,
      '@media(max-width: 1023px), (max-height: 765px)': {
        paddingLeft: 8,
        fontSize: 18,
        width: 55,
        height: 55,
      },
      '& input': {
        textAlign: 'center',
        padding: 0,
        width: 24,
      },
    },
  },
  input: {
    padding: '6px 14px',
  },
  underline: {
    '&::before, &::after': {
      visibility: 'hidden',
    },
  },
};
