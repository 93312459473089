import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  root: {
    height: 48,
  },
  multiline: {
    height: 'auto',
  },
  input: {
    '&$disabled': {
      color: fade(palette.white, 0.4),
      '& $notchedOutline': {
        color: fade(palette.white, 0.2),
      },
    },
  },
};
