import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  root: {
    height: 32,
    paddingTop: 0,
    paddingBottom: 0,
    transition: '.3s',
    textAlign: 'center',
    minHeight: 'auto',
    '&:last-of-type': {
      '& .option': {
        borderBottom: 'none',
      },
    },
    '& .option': {
      width: '100%',
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: `1px solid ${fade(palette.white, 0.3)}`,
    },
    '&$selected': {
      backgroundColor: 'transparent',
      color: palette.primary.main,
    },
  },
  gutters: {
    '&.item--icons': {
      paddingRight: 0,
      textAlign: 'left',
      '& > svg': {
        marginRight: 10,
        minWidth: 24,
      },
      '& .option': {
        paddingRight: 20,
        textTransform: 'capitalize',
      },
      '&$selected': {
        color: palette.white,
      },
    },
  },
};
