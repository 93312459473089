import { fade } from '@material-ui/core';
import palette from 'theme/palette';

export default {
  root: {
    '@media(max-width: 599px)': {
      paddingTop: 56,
    },
    '& .MuiCard-root': {
      background: fade(palette.background.paper, 0.4),
    },
  },
  paper: {
    '@media(max-width: 1023px)': {
      margin: '32px 32px 88px',
    },
  },
  paperWidthSm: {
    maxWidth: 560,
  },
  paperWidthXs: {
    '&.alert': {
      maxWidth: 330,
    },
  },
  paperScrollPaper: {
    '@media(max-width: 1023px)': {
      maxHeight: 'calc(100% - 80px)',
    },
  },
  container: {
    '@media(max-width: 599px)': {
      height: 'calc(100% - 56px)',
    },
  },
};
